//TODO: save per-notification state, to allow dismissal of a single notification
//permanently
angular.module('sq.serverEvents.service',
['sq.serverEvents.serverError.interceptor'])
.factory('serverEvents', function($rootScope, $log, serverError, $timeout) {
  var sE = {
  };

  var eventPrefix = 'sq.serverEvents.service:';
  var _notification = false;
  var _details = false;
  var _final = false;
  var _attempts = 0;
  let _serverDown = false;

  Object.defineProperties(sE, {
    notification: {
      get: function() {
        return _notification;
      },
    },
    details: {
      get: function() {
        return _details;
      },
    },
    final: {
      get: function() {
        return _final;
      },
    },
    attempts: {
      get: function() {
        return _attempts;
      },
    }
  });

  sE.loginCheck = loginCheck;
  sE.eventPrefix = eventPrefix;

  const servErrorPrefix = serverError.eventPrefix;

  $rootScope.$on(servErrorPrefix + 'serverDown', (ev, permanently) => notifyDown(!!permanently));

  $rootScope.$on(servErrorPrefix + 'serverUp', (ev) => notifyUp());

  //expects someone to have triggered an initial login check
  $rootScope.$on('sq.user.auth:loggedIn', () => _clearNotifications());

  $rootScope.$on('sq.user.auth:loggedOut', (ev, err) => {
    $log.debug('received logged out in serverEvents factory');

    if (err) {
      $log.debug('since we found a loggedOut err, running loginCheck');

      loginCheck(true, 'Logged out', err.message);
    }
  });

  $rootScope.$on('sq.user.auth:sessionExpired', function(ev, permanent) {
    $log.debug('received sessionExpired in serverEvents factory');

    if (permanent) {
      loginCheck(true, 'Session expired', 'Please try logging in again');
    } else {
      loginCheck(false, 'Session token updating');
    }
  });

  // This is likely to lead to small weird bugs
  // TODO: move to state-based notification system, where each kind of notification
  // has own state
  $rootScope.$on('sq.user.auth.interceptor:loginConfirmed', function() {
    $log.debug('loginConfirmed message received');

    _clearNotifications();
  });

  $rootScope.$on('sq.jobs.upload.events.service:uploadFailed', function() {
    loginCheck(false, 'Upload Failed', err.message);
  });

  //again not a great system until we make notification-specific messages
  //but for now: this only gets generated when the attempt failed for a 
  //non 403 or 401 error; probably server down;
  // $rootScope.$on('sq.user.auth:loginAttemptFailed', function() {
  //   ++_attempts;
  // });

  // sE.notifyDown = notifyDown;
  // sE.notifyUp = notifyUp;

  sE.dismissNotifications = () => {
    _notification = false;
    _details = false;
  };

  // Use timeout to trigger digest upon connectivity issue
  let notifyDownPromise;
  function notifyDown(final, notif, details) {
    if(notifyDownPromise) {
      $timeout.cancel(notifyDownPromise);
    }

    notifyDownPromise = $timeout(() => {
      _final = final || _final;

      if(_final) {
        serverError.cancelAll();
      } else if (_serverDown) {
        _attempts++;
        return;
      }

      _serverDown = true;

      _notification = notif || (_final ? 'Connection interrupted permanently' : 'Connection interrupted');
      _details = details || (_final ? 'Please try refreshing the page. <br> If this doesn\'t work, <a href="akotlar@emory.edu">please email us</a>' : 'Attempting to reconnect');
      
      $rootScope.$broadcast(eventPrefix + 'serverDown', final);
      notifyDownPromise = null;
    });
  }

  let notifyUpPromise;
  function notifyUp() {
    if(notifyUpPromise) {
      $timeout.cancel(notifyUpPromise);
    }

    notifyUpPromise = $timeout(() => {
      serverError.retryAll();
      _clearNotifications();
      $rootScope.$broadcast(eventPrefix + 'serverUp');
      notifyUpPromise = null;
    });
  }

  function loginCheck(final, notif, details, force) {
    if (!force && _serverDown && !final) { return; }

    _notification = typeof notif !== 'undefined' ? notif : 'Checking login status';
    _details = typeof details !== 'undefined' ? details : 'just a few seconds...';
    _final = final || false;
  }

  // TODO: allow notification state per event type
  // Right now this can ONLY be called if server is up
  function _clearNotifications() {
    _serverDown = false;

    _notification = false;
    _details = false;
    _final = false;
    _attempts = 0;
  }

  return sE;
});
